<div class="stato-code-wrapper">  
  <div class="situazione-code">{{titolo}}</div>
  <div class="code-wrapper" *ngIf="servizi$ | async as servizi">
    <div *ngFor="let servizio of servizi">
      <div class="servizio-wrapper">
        <div class="nome-servizio">{{servizio.nome_servizio}}</div>
        <div class="lunghezza-coda-wrapper">
          <div [class]="'lunghezza-coda ' + lcClass(servizio, servizi)"></div>
          <div [class]="'testo-lunghezza-coda ' + tlcClass(servizio, servizi)">{{testoCoda(servizio)}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
