import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParametriHttpGetService {

  private lsprefix: string;

  constructor(private route: ActivatedRoute) {
    this.lsprefix = 'codesee_';
  }

  /**
   * Legge un parametro HTTP GET, lo salva in localStorage e lo restituisce.
   * Se il parametro manca nell'URL, restituisce quello precedentemente salvato 
   * in localStorage, o null se nemmeno in localStorage è mai stato salvato.
   * @param paramName il nome del parametro HTTP GET da leggere
   */
  getParam(paramName: string, fallbackToLocalStorage = true): string {
    // La teoria, almeno fino a dove l'ho capita, dice che per ottenere i parametri
    // HTTP GET basta prenderli da route.snapshot.queryParamMap.
    // Peccato che non funzioni, o almeno in questo codice non ritorni altro che null...
    let urlValue: string = this.route.snapshot.queryParamMap.get(paramName);

    // ... conseguenza: se non funziona (if (!urlValue) qui sotto) mando a quel paese 
    // Angular e leggo i parametri con il buon vecchio metodo JavaScript puro e semplice 
    // (URLSearchParams), che funziona sempre e che dimostra che il problema è tutto nel
    // fatto che sto usando (magari nel modo sbagliato) Angular, ma i parametri di fatto
    // ci sono...
    if (!urlValue) {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        urlValue = urlParams.get(paramName);
      } catch (err) {
        // ... tranne quando sono su Internet Explorer, dove URLSearchParams non esiste.
        // In questo caso uso un accrochio trovato su StackOverflow e spero che funzioni,
        // ma non ho nessun IE su cui fare il test (né in realtà mi interessa testarlo,
        // diciamo che è un best effort, se funziona ben venga, se non funziona si fotta
        // l'utente che nel 2024+ usa ancora IE)
        if (!urlValue) {
          const name = paramName.replace(/[\[\]]/g, '\\$&');
          var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(window.location.href);
          if (results) {
            if (!results[2]) {
              urlValue = '';
            } else {
              urlValue = decodeURIComponent(results[2].replace(/\+/g, ' '));        
            }
          }        
        }
      }
    }
    if (urlValue) {
      localStorage.setItem(this.lsprefix + paramName, urlValue);
      return urlValue;
    } else {
      if (fallbackToLocalStorage) {
        return localStorage.getItem(this.lsprefix + paramName);
      } else {
        return urlValue;
      }
    }
  }

  setParam(paramName: string, paramValue: string) {
    localStorage.setItem(this.lsprefix + paramName, paramValue);
  }

  uid(): string {
    return this.getParam('uid');
  }

  cust(fallbackToLocalStorage = true): string {
    const prefix = 'CUSTOMER-';
    let result = this.getParam('cust', fallbackToLocalStorage);
    if (result && result.indexOf(prefix) >= 0)
      result = result.substring(prefix.length);
    return result;
  }

  ticket(): string {
    return this.getParam('ticket');
  }

  setTicket(value: string) {
    this.setParam('ticket', value);
  }

  public earlyInit() {
    this.uid();
    this.cust();
    this.ticket();
  }
}
