import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ParametriHttpGetService } from './parametri-http-get.service';
import { FirebaseService } from './firebase.service';

export class CodaTicket {
  id_satisfaction: string; // CUSTOMER-xyz123
  id_servizio: string; 
  turno: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatoAttualeTicketService {

  constructor(private afs: FirebaseService,
              private phg: ParametriHttpGetService) { }

  
  public get(idUser: string, idSatisfaction: string): Promise<Observable<CodaTicket>> {
    const result = new Promise<Observable<any>>((resolve, reject) => {
      const path = 'users/' + idUser + '/tickets/' + idSatisfaction;
      this.afs.getAfs().then(afs => {
        resolve(afs.doc<CodaTicket>(path).valueChanges());
        }).catch(error => { reject(error)});
      });
    return result;
  } 

}
 