import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

export class UserInfo {
  logo: string; // base64 PNG
  logo_css_style: string; // opzionale, se impostato viene messo come style="..." nel <div> del logo
  id_utente: string;
  monitoraggioTicketless?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  public get(afs: AngularFirestore, idUser: string): Observable<UserInfo> {
    const path = 'users/' + idUser;
    return afs.doc<UserInfo>(path).valueChanges();
  } 

}
