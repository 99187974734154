import { Injectable } from '@angular/core';
import { FirebaseAuthService } from '../firebase/auth/firebase-auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  
  private loggedIn: boolean;

  constructor(private faService: FirebaseAuthService,
              private afs: AngularFirestore) { }

  public getAfs(): Promise<AngularFirestore> {

    const result = new Promise<AngularFirestore>((resolve, errmanager) => {
      if (!this.loggedIn) {
        // Lucio, 2021-02-16:
        // Now I know this ugly sh!t is not going to make anything more secure, but,
        // back when we designed the whole thing, we designed it knowing of this gaping hole:
        // app end users are NOT supposed to sign up nor to sign in, but they ARE
        // supposed to read the database.
    
        
        this.faService.signInWithEmail(window.atob('dGVvbS5kZXYuY29kZXNlZUB0ZW9tLml0'),
            window.atob('QVZlcnlMb25nQW5kVXNlbGVzc1Bhc3N3b3JkVGhhdEFueW9uZUNhblNuaWZmSW5UaGVDb2Rlc2VlV2ViYXBwU291cmNlcw')).
            then(res => {
              this.loggedIn = true;
              resolve(this.afs);
            });
        } else {
          resolve(this.afs);
        }
    
    });
    return result;
  }
}