import { Component, OnInit } from '@angular/core';
import { UserInfo, UserService } from '../../api/user.service';
import { ParametriHttpGetService } from '../../api/parametri-http-get.service';
import { FirebaseService } from '../../api/firebase.service';



@Component({
  selector: 'app-denominazione-esercizio',
  templateUrl: './denominazione-esercizio.component.html',
  styleUrls: ['./denominazione-esercizio.component.scss']
})
export class DenominazioneEsercizioComponent implements OnInit {

  
  css_logo: string;
  constructor(private uService: UserService,
              private phgService: ParametriHttpGetService,
              private fbSvc: FirebaseService
    ) { }

  async ngOnInit() {
    const uid: string = this.phgService.uid();
    if (uid) {
      const afs = await this.fbSvc.getAfs();
      const sub = this.uService.get(afs, uid).subscribe((value: UserInfo) => {
        sub.unsubscribe();
        this.css_logo = 'background-image: url(data:image/png;base64,' + value.logo + ');';
        if (value.logo_css_style) {
          this.css_logo = this.css_logo + value.logo_css_style;
        }
      });
    }        
  }
}
