import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { ParametriHttpGetService } from '../../api/parametri-http-get.service';
import { StatoAttualeCodaService, StatoCoda } from '../../api/stato-attuale-coda.service';
import { map } from 'rxjs/operators';
import { UserService } from '../../api/user.service';
import { FirebaseService } from '../../api/firebase.service';

@UntilDestroy()
@Component({
  selector: 'app-stato-attuale',
  templateUrl: './stato-attuale.component.html',
  styleUrls: ['./stato-attuale.component.scss'],
})
export class StatoAttualeComponent implements OnInit { 
  public servizi$: Observable<StatoCoda[]>;
  public titolo: string;

  constructor(private sacService: StatoAttualeCodaService,
              private phgService: ParametriHttpGetService,
              private usrSvc: UserService,
              private fbSvc: FirebaseService) { }

  async ngOnInit() {
    this.titolo = "";
    const uid: string = this.phgService.uid();
    if (uid) {
      const afs = await this.fbSvc.getAfs();
      const sub = this.usrSvc.get(afs, uid).subscribe(async (u) => {
        sub.unsubscribe();
        if (u.monitoraggioTicketless === true) {
          this.titolo = "Situazione code";
          this.servizi$ = (await this.sacService.getAll(uid)).pipe(map((scs: StatoCoda[]) => 
          this.fixCount(scs.filter((sc: StatoCoda) => sc.nome_servizio && 
                                      sc.nome_servizio.trim().length > 0 && 
                                      sc.app && sc.app > 0)
            .sort((sc1: StatoCoda, sc2: StatoCoda) => sc1.nome_servizio.localeCompare(sc2.nome_servizio)))));
        } else {
          this.titolo = "Servizio non attivo";
          this.servizi$ = new Subject<StatoCoda[]>();
        }
      });
    }        
  }

  fixCount(scs: StatoCoda[]): StatoCoda[] {
    return scs.map((sc: StatoCoda) => {
      const inAttesa = this.sacService.ticketInAttesa(sc);
      sc.tickets = sc.tickets ? sc.tickets.slice(0, inAttesa) : []; // non importa quali ticket tengo nell'array, ma importa solo quanti
      return sc;
    })
  }

  lcClass(srv: StatoCoda, asrv: StatoCoda[]) {
     return "TODO"; // TODO
  }

  tlcClass(srv: StatoCoda, asrv: StatoCoda[]) {
    return "TODO"; // TODO    
  }
  
  testoCoda(srv: StatoCoda) {
    return this.sacService.ticketInAttesa(srv);    
  }
}

