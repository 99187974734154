<div class="stato-coda-wrapper" *ngIf="stato_coda$ | async as stato">
  
  
  <div class="nome-servizio-wrapper">
    <div class="nome-servizio">{{stato.nome_servizio}}</div>

    <div class="il-tuo-ticket">Il tuo biglietto</div>

    <div [class]="classe_lunghezza_stringa_mio + ' codice-mio-ticket'">{{turno_proprietario_ticket}}</div>
  </div>

  <div class="messaggio-wrapper">
    <div [class]="'messaggio ' + classe_messaggio">
      {{messaggio}}
    </div>
  </div>

  <div class="informazioni-coda-wrapper">
    <div class="numero-servito-wrapper infocoda-box">
      <div class="numero-servito-label infocoda-label">
        Numero servito
      </div>
      <div [class]="classe_lunghezza_stringa_attuale + ' numero-servito numero-servito-value'">
        {{stato.turno}}
      </div>
    </div>
    <div class="sportello-wrapper infocoda-box">
      <div class="sportello-label infocoda-label">
        {{stato.tipo_sportello ? stato.tipo_sportello : 'Sportello'}}
      </div>
      <div [class]="classe_lunghezza_stringa_sportello + ' sportello sportello-value'">
        <!-- <div class="sportello sportello-value"></div> -->
        {{stato.numero_sportello ? stato.numero_sportello : '--'}}
      </div>
      
    </div>
    <div class="persone-in-attesa-wrapper infocoda-box">
      <div class="persone-in-attesa-label infocoda-label">
        Persone in attesa<br>prima di te
      </div>
      <div [class]="classe_lunghezza_stringa_persone + ' persone-in-attesa infocoda-value'">
        <!-- <div class="persone-in-attesa infocoda-value"></div> -->
        {{persone_in_attesa}}
      </div>
      
    </div>
    <div class="tempo-medio-wrapper infocoda-box">
      <div class="tempo-medio-label infocoda-label">
        Tempo medio di<br>chiamata (min)
      </div>
      <div [class]="classe_lunghezza_stringa_tmc + ' tempo-medio tmc-value'">
        <!-- <div class="tempo-medio tmc-value"></div> -->
        {{strTmcMinuti}}
      </div>
      
    </div>
  </div>

</div>
