import { Component, OnInit } from '@angular/core';
import { CodaTicket, StatoAttualeTicketService } from '../../api/stato-attuale-ticket.service';
import { StatoAttualeCodaService, StatoCoda } from '../../api/stato-attuale-coda.service';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { ParametriHttpGetService } from '../../api/parametri-http-get.service';
import { multicast, refCount } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-stato-coda',
  templateUrl: './stato-coda.component.html',
  styleUrls: ['./stato-coda.component.scss'],
})
export class StatoCodaComponent implements OnInit { 
  public stato_coda$: Observable<StatoCoda>;
  public turno_proprietario_ticket: string;
  public persone_in_attesa: string;
  public classe_lunghezza_stringa_persone: string;
  public classe_lunghezza_stringa_sportello: string;
  public classe_lunghezza_stringa_attuale: string;
  public classe_lunghezza_stringa_mio: string;
  public classe_lunghezza_stringa_tmc: string;
  public messaggio: string;
  public classe_messaggio: string;
  public strTmcMinuti: string;

  private statoCodaSubscription: Subscription = null;

  constructor(private satService: StatoAttualeTicketService,
              private sacService: StatoAttualeCodaService,
              private phgService: ParametriHttpGetService) { }

  ngOnInit() {
    const uid: string = this.phgService.uid();
    const cust: string = this.phgService.cust();
    this.classe_lunghezza_stringa_mio = "";
    this.classe_lunghezza_stringa_attuale = "";
    this.classe_lunghezza_stringa_sportello = "";
    this.classe_lunghezza_stringa_persone = "";
    this.classe_lunghezza_stringa_tmc = "";
    this.messaggio = "Benvenuto";
    this.persone_in_attesa = '∞';
    this.turno_proprietario_ticket = '?';
    this.classe_messaggio = '';
    this.strTmcMinuti = '∞'
    if (uid && cust) {
      const codaTicketObservable = this.wrapCodaTicketObservable(this.satService.get(uid, cust));
      codaTicketObservable.subscribe((ctValue: CodaTicket) => {
        const idCoda = ctValue.id_servizio;

        if (this.statoCodaSubscription != null) { // se ho già una subscribe attiva su questo stato coda
          this.statoCodaSubscription.unsubscribe(); // la cancello
        }

        // Creo un multicasted observable perché sia questo codice, sia il template html,
        // devono fare una subscribe all'observable e voglio evitare che l'observable chiami
        // firebase due volte per ottenere lo stesso valore
        const statoCodaObservable = this.wrapStatoCodaObservable(this.sacService.get(uid, idCoda));
        const sbj = new Subject<StatoCoda>();
        this.stato_coda$ = statoCodaObservable.pipe(multicast(sbj), refCount());

        this.statoCodaSubscription = this.stato_coda$.subscribe((value: StatoCoda) => {
          const mioTicketInCoda = this.sacService.ticketInAttesaPrimaDiMe(value);
          let npiapdm = "-"; // Num Pers In Attesa Prima Di Me
          if (mioTicketInCoda == null) { // il mio ticket non è presente nell'array
            // quindi è necessariamente scaduto
            this.messaggio = 'Biglietto già chiamato e non più in coda';
            this.classe_messaggio = 'messaggio-turno-perso';
            npiapdm = "--";
            this.statoCodaSubscription.unsubscribe(); // smetto di ricevere aggiornamenti
          } else {
            if (mioTicketInCoda.pausa) { // se sono in pausa significa che mi stanno servendo 
              this.messaggio = "Attendi di essere richiamato";
              this.classe_messaggio = "messaggio-in-pausa";
              npiapdm = '0';
            } else {
              if (mioTicketInCoda.chiamato) {
                this.messaggio = 'Sei stato chiamato';
                this.classe_messaggio = "messaggio-tocca-a-te";
                npiapdm = '0';
              } else {
                this.messaggio = '';
                this.classe_messaggio = '';
                npiapdm = '' + mioTicketInCoda.numero_ticket_davanti;
              }
            }
          }
          this.turno_proprietario_ticket = ctValue.turno;
          this.classe_lunghezza_stringa_mio = this.classeLunghezzaStringa(this.turno_proprietario_ticket);
          this.persone_in_attesa = npiapdm;
          this.classe_lunghezza_stringa_persone = this.classeLunghezzaStringa("" + this.persone_in_attesa);
          this.classe_lunghezza_stringa_attuale = this.classeLunghezzaStringa(value.turno);
          this.classe_lunghezza_stringa_sportello = this.classeLunghezzaStringa(value.numero_sportello);
          let tmcMinuti = value.tmc / 60.0;
          this.strTmcMinuti = '' + Math.round(tmcMinuti);
          this.classe_lunghezza_stringa_tmc = this.classeLunghezzaStringa(this.strTmcMinuti);
        });

      });

    }        
    
  }

  private classeLunghezzaStringa(stringa: string): string {
    let len = 1;
    if (typeof stringa === 'string') {
      len = stringa.length;
    }
    return 'strlen' + len;
  }

  /**
   * Questa funzione serve a creare un Observable<StatoCoda> invece di un Observable<any>.
   * Il servizio mi ritorna una Promise, che mi consegna un Observable<any> (che poi è
   * esattmanente l'Observable resitutito dalla valueChanges() di firebase).
   * Questo binding fra la struttura di firebase e la mia classe StatoCoda vorrei farlo
   * nel servizio, ma per farlo là devo fare là anche la subscribe all'Observable di firebase.
   * Purtroppo chiamare la subscribe all'interno dei servizi è una `BAD PRACTICE` ™, infatti
   * nei servizi non posso usare la untilDestroyed() che uso qui per ottenere la unsubscribe
   * automatica (non posso perché un servizio non è un componente e non ha la ngOnDestroy()). 
   * @param promise 
   */
  private wrapStatoCodaObservable(promise: Promise<Observable<any>>): Observable<StatoCoda> {
    const result = new Observable<StatoCoda>((observer: Observer<StatoCoda>) => {
      promise.then(scObservable => {
        scObservable.pipe(untilDestroyed(this)).subscribe((value) => {
          const result_payload: StatoCoda = new StatoCoda();
          Object.assign(result_payload, value);
          observer.next(result_payload);
          })}, reject => {
              observer.error(reject);
      });
    });
    return result;
  }

  /**
   * vedi la wrapStatoCodaObservable qui sopra, stesso discorso
   * @param promise 
   */
  private wrapCodaTicketObservable(promise: Promise<Observable<any>>): Observable<CodaTicket> {
    const result = new Observable<CodaTicket>((observer: Observer<CodaTicket>) => {
      promise.then(ctObservable => {
        ctObservable.pipe(untilDestroyed(this)).subscribe((value) => {
          const result_payload: CodaTicket = new CodaTicket();
          Object.assign(result_payload, value);
          observer.next(result_payload);
          })}, reject => {
              observer.error(reject);
      });
    });
    return result;
  }
}
