// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD9R16oBnD8qeXjnvpubW5LI_RXYlF2M-M",
    authDomain: "codesee-d5984.firebaseapp.com",
    projectId: "codesee-d5984",
    databaseURL: "https://codesee-d5984-default-rtdb.firebaseio.com",
    storageBucket: "codesee-d5984.appspot.com",
    messagingSenderId: "450421659359",
    appId: "1:450421659359:web:271626e199413198b83b6a",
    measurementId: "G-S4H4SMZ8F8"

  },
  appShellConfig: {
    debug: false,
    networkDelay: 0
  }
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
