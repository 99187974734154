import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParametriHttpGetService } from './parametri-http-get.service';
import { FirebaseService } from './firebase.service';


export class Ticket {
  id_satisfaction: string;
  turno: string;
  pausa: number;
  chiamato: number;
  numero_ticket_davanti: number;
}

export class StatoCoda {
  idCoda: string;
  chiamato: number;
  end: number;
  flag: number;
  id_lang: number;
  id_satisfaction: string;
  id_servizio: number;
  id_sportello: any;
  id_ticket: number;
  app?: number;
  lettera_turno: string;
  nome_servizio: string;
  numero_turno: number;
  tmc: number;
  turno: string; // ultimo chiamato
  tickets: Array<Ticket>;
  tipo_sportello: string; // "Ambulatorio", "Stanza", ...
  numero_sportello: string;
}


@Injectable({
  providedIn: 'root'
})
export class StatoAttualeCodaService {
  
  constructor(private afs: FirebaseService,
              private phg: ParametriHttpGetService) { }

  get(idUser: string, idCoda: string): Promise<Observable<StatoCoda>> {
    const result = new Promise<Observable<any>>((resolve, reject) => {
      const path = 'users/' + idUser + '/statoattuale/' + idCoda;
      this.afs.getAfs().then(afs => {
        resolve(afs.doc<StatoCoda>(path).valueChanges());
        }).catch(error => { reject(error)});
      });
    return result;
  }

  getAll(idUser: string): Promise<Observable<StatoCoda[]>> {
    const result = new Promise<Observable<any>>((resolve, reject) => {
      const path = 'users/' + idUser + '/statoattuale';
      this.afs.getAfs().then(afs => {
        const coll = afs.collection(path);
        resolve(coll.valueChanges());
        }).catch(error => { reject(error)});
      });
    return result;
  }
  
  ticketInAttesaPrimaDiMe(statoCoda: StatoCoda): Ticket | null {
    let trovato = false;
    let numeroTicketRilevantiAiFiniISTAT = 0; // N.B. ovviamente l'ISTAT non c'entra niente: significa "ai fini statistici"
    let result: Ticket = null;
    const nTickets = statoCoda.tickets ? statoCoda.tickets.length : 0;
    for (let index = 0; index < nTickets; index++) {
      const ticket = statoCoda.tickets[index];
      if (ticket.id_satisfaction == this.phg.cust()) {
        result = {...ticket}; // object cloning
        result.numero_ticket_davanti = numeroTicketRilevantiAiFiniISTAT;
        trovato = true;
        break;
      } else { // non è il mio ticket, quindi lo conteggio come davanti solo se
               //  chiamato = 1 e pausa = 1 (chiamato ma messo in pausa davanti a me)
               //     oppure
               //  chiamato = 0 e pausa = 0 (non è in pausa e non è ancora stato chiamato)
               // mentre se è chiamato e non in pausa (chiamato = 1 e pausa = 0) non devo 
               // contarlo, perché non è in attesa, ma lo stanno servendo.
               // Il caso chiamato = 0 e pausa = 1 è impossibile.
               //
               // In pratica, per scriverla in modo conciso, lo conteggio solo 
               // quando chiamato == pausa (cioè entrambi 0 o entrambi 1)
               // :D   uccidetemi.
        if (ticket.chiamato == ticket.pausa) {
          numeroTicketRilevantiAiFiniISTAT++;
        }
      }
    }
    if (trovato)
      return result;
    else
      return null;
  }

  ticketInAttesa(statoCoda: StatoCoda): number {
    let numeroTicketRilevantiAiFiniISTAT = 0; // N.B. ovviamente l'ISTAT non c'entra niente: significa "ai fini statistici"
    const nTickets = statoCoda.tickets ? statoCoda.tickets.length : 0;
    for (let index = 0; index < nTickets; index++) {
      const ticket = statoCoda.tickets[index];
      //  chiamato = 1 e pausa = 1 (chiamato ma messo in pausa davanti a me)
      //     oppure
      //  chiamato = 0 e pausa = 0 (non è in pausa e non è ancora stato chiamato)
      // mentre se è chiamato e non in pausa (chiamato = 1 e pausa = 0) non devo 
      // contarlo, perché non è in attesa, ma lo stanno servendo.
      // Il caso chiamato = 0 e pausa = 1 è impossibile.
      //
      // In pratica, per scriverla in modo conciso, lo conteggio solo 
      // quando chiamato == pausa (cioè entrambi 0 o entrambi 1)
      // :D   uccidetemi.
      if (ticket.chiamato == ticket.pausa) {
        numeroTicketRilevantiAiFiniISTAT++;
      }
    }
    return numeroTicketRilevantiAiFiniISTAT;
  }

}
